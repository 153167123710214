import React from 'react'
import { withTheme } from '../Helpers/themes'
import { useStaticQuery, graphql } from 'gatsby'
import './skills.scss'
import { MDBCard, MDBCardBody } from 'mdbreact'

const Skills = props => {
  const { allContentfulSkills } = useStaticQuery(
    graphql`
      query {
        allContentfulSkills {
          edges {
            node {
              skillsTitle
              childContentfulSkillsSkillsEntriesJsonNode {
                internal {
                  content
                }
              }
            }
          }
        }
      }
    `
  )

  const common = allContentfulSkills.edges[0].node
  const skillsTitle = common.skillsTitle
  const skillsEntries = JSON.parse(
    common.childContentfulSkillsSkillsEntriesJsonNode.internal.content
  ).skillsEntries

  return (
    <div className="skills py-5" style={props.theme.tertiaryBackgroundColor}>
      <div className="container">
        <h3
          className="skills-title font-weight-bold text-center"
          style={props.theme.primaryColor}
        >
          {skillsTitle}
        </h3>
        <div className="skills-block">
          <div className="row justify-content-center">
            {skillsEntries.map((entry, index) => (
              <div key={index} className="col-lg-4 col-md-6 col-12">
                <MDBCard className="skills-card">
                  <MDBCardBody>
                    <h3 className="skills-card-title text-center">
                      {entry.title}
                    </h3>
                    <div
                      style={props.theme.primaryBackgroundColor}
                      className="skills-icon-holder position-absolute d-inline-block rounded-circle text-center"
                    >
                      <i className={entry.icon}></i>
                    </div>
                    <ul className="list-unstyled">
                      {entry.entries.map((entry, index) => (
                        <li key={index} className="list-item">
                          <i
                            style={props.theme.primaryColor}
                            className="fas fa-check"
                          ></i>
                          {entry}
                        </li>
                      ))}
                    </ul>
                  </MDBCardBody>
                </MDBCard>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}

export default withTheme(Skills)
