import React, { useState, useEffect } from 'react'
import { withTheme } from '../Helpers/themes'
import { useStaticQuery, graphql } from 'gatsby'
import './weather-section.scss'
import Img from 'gatsby-image'
import { MDBCard, MDBCardBody } from 'mdbreact'
import axios from 'axios'
import { weatherThemes } from '../Helpers/weather-themes'

const WeatherSection = props => {
  const { allContentfulWeatherSection } = useStaticQuery(
    graphql`
      query {
        allContentfulWeatherSection {
          edges {
            node {
              weatherTitle
              weatherCardLabels {
                internal {
                  content
                }
              }
              cityPhotos {
                fluid {
                  ...GatsbyContentfulFluid_tracedSVG
                }
                description
              }
            }
          }
        }
      }
    `
  )

  const common = allContentfulWeatherSection.edges[0].node
  const cityPhotos = common.cityPhotos
  const weatherCardLabels = common.weatherCardLabels

  const [weather, setWeather] = useState({
    temperature: 0,
    description: weatherThemes[6].themeName,
    icon: weatherThemes[6].icon,
    iconColor: weatherThemes[6].iconColor,
    textColor: weatherThemes[6].textColor,
    backgroundColor: weatherThemes[6].backgroundColor,
    windHumidityPressure: ['NW 3.6 mph', '35%', '30.06 inhg'],
  })

  const startAnimation = () => {
    let movingRectangleBigClassList = document.getElementById(
      'moving-rectangle-big'
    ).classList
    movingRectangleBigClassList.remove('overlay-animation')
    setTimeout(() => movingRectangleBigClassList.add('overlay-animation'), 0)

    let movingRectangleSmallClassList = document.getElementById(
      'moving-rectangle-small'
    ).classList
    movingRectangleSmallClassList.remove('overlay-animation')
    setTimeout(() => movingRectangleSmallClassList.add('overlay-animation'), 0)

    document
      .getElementById('photo-big-overlay')
      .classList.remove('overlay-animation')
  }
  const endAnimation = () => {
    document
      .getElementById('photo-big-overlay')
      .classList.add('overlay-animation')
  }

  const [photoIndex, setPhotoIndex] = useState(2)
  const setPreviousPhotoIndex = () => {
    startAnimation()
    setTimeout(() => {
      endAnimation()
      setPhotoIndex(
        photoIndex - 1 != 1 ? photoIndex - 1 : cityPhotos.length - 1
      )
    }, 250)
  }
  const setNextPhotoIndex = () => {
    startAnimation()
    setTimeout(() => {
      endAnimation()
      setPhotoIndex(photoIndex + 1 != cityPhotos.length ? photoIndex + 1 : 2)
    }, 250)
  }
  const getNextPhotoIndex = () => {
    return photoIndex + 1 != cityPhotos.length ? photoIndex + 1 : 2
  }

  const cardinalDirection = [
    'N',
    'NNE',
    'NE',
    'ENE',
    'E',
    'ESE',
    'SE',
    'SSE',
    'S',
    'SSW',
    'SW',
    'WSW',
    'W',
    'WNW',
    'NW',
    'NNW',
  ]
  const convertDegreesToDirection = degrees => {
    const direction = Math.round(degrees / 22.5 + 0.5)
    return cardinalDirection[direction % 16]
  }
  const convertHpaToInhg = hpa => {
    return Math.round((hpa / 33.863886666667) * 100) / 100
  }

  useEffect(() => {
    const fetchData = async () => {
      const openweathermapapi = await axios(
        `https://api.openweathermap.org/data/2.5/weather?q=Montreal,ca&units=metric&appid=bb0b87b1882cee3603c31ab7d568f463`
      )
      const response = await openweathermapapi
      let main = response.data.weather[0].main
      let icon = weather.icon,
        iconColor = weather.iconColor,
        textColor = weather.textColor,
        backgroundColor = weather.backgroundColor
      let mainInWeatherThemes = false
      weatherThemes.map(entry => {
        if (entry.themeName == main) {
          mainInWeatherThemes = true
          const hours = new Date().getHours()
          let isDayTime = hours > 6 && hours < 20
          if (isDayTime) {
            icon = entry.icon
            iconColor = entry.iconColor
            textColor = entry.textColor
            backgroundColor = entry.backgroundColor
          } else {
            icon = entry.nightIcon
            iconColor = entry.nightIconColor
            textColor = entry.nightTextColor
            backgroundColor = entry.nightBackgroundColor
          }
        }
      })
      if (!mainInWeatherThemes) {
        main = weather.description
      }
      setWeather({
        temperature: Math.ceil(response.data.main.temp) + '°',
        description: main,
        icon: icon,
        iconColor: iconColor,
        textColor: textColor,
        backgroundColor: backgroundColor,
        windHumidityPressure: [
          response.data.wind.deg && response.data.wind.speed
            ? convertDegreesToDirection(response.data.wind.deg) +
              ' ' +
              response.data.wind.speed +
              ' mph'
            : 'NW' + ' ' + response.data.wind.speed + ' mph',
          response.data.main.humidity + '%',
          convertHpaToInhg(response.data.main.pressure) + ' inhg',
        ],
      })
    }
    fetchData()
  }, [])

  return (
    <div className="py-5" style={props.theme.secondaryBackgroundColor}>
      <div className="container">
        <h3 className="weather-title font-weight-bold text-white text-center">
          {common.weatherTitle}
        </h3>
        <div className="weather-block">
          <div className="row justify-content-center">
            <div className="col-lg-4 col-md-6 col-12">
              <MDBCard className="photo-card">
                <div className="overlay">
                  <h4 className="photo-card-title text-white text-center">
                    {cityPhotos[0].description}
                  </h4>
                </div>
                <Img
                  className="photo-card-cover"
                  alt="montreal-picture"
                  fluid={cityPhotos[0].fluid}
                />
              </MDBCard>
            </div>
            <div className="col-lg-4 col-md-6 col-12">
              <MDBCard className="photo-card">
                <div className="overlay">
                  <h4 className="photo-card-title text-white text-center">
                    {cityPhotos[1].description}
                  </h4>
                </div>
                <Img
                  className="photo-card-cover"
                  alt="montreal-picture"
                  fluid={cityPhotos[1].fluid}
                />
              </MDBCard>
            </div>
            <div className="col-lg-4 col-md-6 col-12">
              <MDBCard className="weather-card">
                <div
                  style={{ backgroundColor: weather.backgroundColor }}
                  className="header-section"
                >
                  <i
                    style={{ background: weather.iconColor }}
                    className={'weather-card-icon ' + weather.icon}
                  ></i>
                  <h4
                    style={{ color: weather.textColor }}
                    className="weather-card-description text-right"
                  >
                    {weather.description}
                  </h4>
                  <h4
                    style={{ color: weather.textColor }}
                    className="weather-card-temperature text-right"
                  >
                    {weather.temperature}
                  </h4>
                </div>
                <MDBCardBody className="details-section">
                  {weatherCardLabels.map((entry, index) => (
                    <div key={index} className="details-row">
                      <p className="details-item">
                        {entry.internal.content.replace(/"/g, '')}
                      </p>
                      <p className="details-item text-right">
                        {weather.windHumidityPressure[index]}
                      </p>
                    </div>
                  ))}
                </MDBCardBody>
              </MDBCard>
            </div>
          </div>
        </div>
        <div className="weather-block-2">
          <div className="row justify-content-center">
            <div className="col-12">
              <MDBCard>
                <div className="photo-container-big">
                  <div id="moving-rectangle-big"></div>
                  <div id="photo-big-overlay" className="overlay-animation">
                    <h4 className="photo-big-title text-white text-center">
                      {cityPhotos[photoIndex].description}
                    </h4>
                  </div>
                  <Img
                    className="photo-big"
                    alt="montreal-picture"
                    fluid={cityPhotos[photoIndex].fluid}
                  />
                  <div className="arrow left">
                    <button
                      aria-label="carousel-previous-photo"
                      style={props.theme.primaryBackgroundColor}
                      className="btn-floating btn-large"
                      onClick={() => setPreviousPhotoIndex()}
                    >
                      <i className="fa fa-arrow-left"></i>
                    </button>
                  </div>
                  <div className="arrow right">
                    <button
                      aria-label="carousel-next-photo"
                      style={props.theme.primaryBackgroundColor}
                      className="btn-floating btn-large"
                      onClick={() => setNextPhotoIndex()}
                    >
                      <i className="fa fa-arrow-right"></i>
                    </button>
                  </div>
                </div>
                <div className="photo-container-small">
                  <div id="moving-rectangle-small"></div>
                  <div className="header-section">
                    <Img
                      className="photo-small"
                      alt="montreal-picture"
                      fluid={cityPhotos[getNextPhotoIndex()].fluid}
                    />
                  </div>
                </div>
              </MDBCard>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default withTheme(WeatherSection)
