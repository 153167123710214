import React from 'react'
import { withTheme } from '../Helpers/themes'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import './built-with-section.scss'

const BuiltWithSection = props => {
  const { allContentfulTechnologiesUsed } = useStaticQuery(
    graphql`
      query {
        allContentfulTechnologiesUsed {
          edges {
            node {
              builtWith
              techLogos {
                fixed(width: 60) {
                  ...GatsbyContentfulFixed_tracedSVG
                }
                description
              }
            }
          }
        }
      }
    `
  )

  const common = allContentfulTechnologiesUsed.edges[0].node
  const techLogos = common.techLogos

  return (
    <div className="py-5" style={{ backgroundColor: '#DCE7EC' }}>
      <div className="container">
        <h3 className="built-with-title font-weight-bold text-center">
          {common.builtWith}
        </h3>
        <div className="logos-block">
          <div className="row justify-content-center">
            {techLogos.map((entry, index) => (
              <a
                key={index}
                rel="noopener"
                href={entry.description}
                target="_blank"
              >
                <Img
                  className="tech-logo-picture"
                  alt="tech-logo-picture"
                  fixed={entry.fixed}
                />
              </a>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}

export default withTheme(BuiltWithSection)
