import React from 'react'
import { withTheme } from '../Helpers/themes'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import './hero-banner.scss'

const HeroBanner = props => {
  const { allContentfulHeroBanner } = useStaticQuery(graphql`
    query {
      allContentfulHeroBanner {
        edges {
          node {
            helloText
            nameText
            descriptionText {
              descriptionText
            }
            personalPhoto {
              fixed(width: 240) {
                ...GatsbyContentfulFixed_tracedSVG
              }
            }
          }
        }
      }
    }
  `)
  const common = allContentfulHeroBanner.edges[0].node
  const helloText = common.helloText
  const nameText = common.nameText
  const descriptionText = common.descriptionText.descriptionText
  const personalPhoto = common.personalPhoto.fixed

  return (
    <div
      className="text-white py-5"
      style={props.theme.secondaryBackgroundColor}
    >
      <div className="container">
        <div className="media flex-column flex-md-row">
          <Img
            className="personal-picture mb-3 mb-md-0 mr-md-5 ml-md-0 rounded mx-auto"
            alt="personal-picture"
            fixed={personalPhoto}
          />
          <div className="media-body text-center text-md-left">
            <div className="lead">{helloText}</div>
            <h2 className="mt-0 display-4 font-weight-bold">{nameText}</h2>
            <div className="bio">{descriptionText}</div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default withTheme(HeroBanner)
