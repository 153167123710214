import React from 'react'
import { Helmet } from 'react-helmet'
import './index.scss'
import { connect } from 'react-redux'
import { mapStateToProps } from '../state/mapState'
import { mapDispatchToProps } from '../state/mapDispatch'
import HeroBanner from '../components/HeroBanner/hero-banner'
import Skills from '../components/Skills/skills'
import BuiltWithSection from '../components/BuiltWithSection/built-with-section'
import WeatherSection from '../components/WeatherSection/weather-section'
import BackToTop from '../components/BackToTop/backToTop'
import Navbar from '../components/Navbar/navbar'
import { ThemeProvider } from '../components/Helpers/themes'

const Index = props => {
  return (
    <div className="application">
      <Helmet>
        <title>Alex Verzea's Site</title>
        <meta name="description" content="AV Site" />
        <link rel="canonical" href="https://av-site.com" />
      </Helmet>
      <ThemeProvider theme={props.theme}>
        <Navbar
          theme={props.theme}
          themes={props.themes}
          changeTheme={props.changeTheme}
        />
        <HeroBanner theme={props.theme} />
        <Skills theme={props.theme} />
        <WeatherSection theme={props.theme} />
        <BuiltWithSection theme={props.theme} />
        <BackToTop theme={props.theme} />
      </ThemeProvider>
    </div>
  )
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Index)
