export const weatherThemes = [
  {
    themeName: 'Thunderstorm',
    icon: 'fas fa-cloud-rain',
    iconColor:
      'linear-gradient(to top, #51D3FC 0%, #51D3FC 34%, #A4B2B9 35%, #A4B2B9 100%)',
    textColor: '#3C6D82',
    backgroundColor: '#C6EFFF',
    nightIcon: 'fas fa-cloud-moon-rain',
    nightIconColor:
      'linear-gradient(to top right, #51D3FC 0%, #3A444D 35%, #3A444D 60%, #D5DCF7 63%, #D5DCF7 100%)',
    nightTextColor: '#51D3FC',
    nightBackgroundColor: '#222335',
  },
  {
    themeName: 'Drizzle',
    icon: 'fas fa-cloud-rain',
    iconColor:
      'linear-gradient(to top, #51D3FC 0%, #51D3FC 34%, #A4B2B9 35%, #A4B2B9 100%)',
    textColor: '#3C6D82',
    backgroundColor: '#C6EFFF',
    nightIcon: 'fas fa-cloud-moon-rain',
    nightIconColor:
      'linear-gradient(to top right, #51D3FC 0%, #3A444D 35%, #3A444D 60%, #D5DCF7 63%, #D5DCF7 100%)',
    nightTextColor: '#51D3FC',
    nightBackgroundColor: '#222335',
  },
  {
    themeName: 'Rain',
    icon: 'fas fa-cloud-rain',
    iconColor:
      'linear-gradient(to top, #51D3FC 0%, #51D3FC 34%, #A4B2B9 35%, #A4B2B9 100%)',
    textColor: '#3C6D82',
    backgroundColor: '#C6EFFF',
    nightIcon: 'fas fa-cloud-moon-rain',
    nightIconColor:
      'linear-gradient(to top right, #51D3FC 0%, #3A444D 35%, #3A444D 60%, #D5DCF7 63%, #D5DCF7 100%)',
    nightTextColor: '#51D3FC',
    nightBackgroundColor: '#222335',
  },
  {
    themeName: 'Snow',
    icon: 'fas fa-snowflake',
    iconColor: '#FFFFFF',
    textColor: '#FFFFFF',
    backgroundColor: '#3A5984',
    nightIcon: 'fas fa-snowflake',
    nightIconColor: '#D6DDF8',
    nightTextColor: '#D6DDF8',
    nightBackgroundColor: '#222335',
  },
  {
    themeName: 'Clear',
    icon: 'fas fa-sun',
    iconColor: '#FFC102',
    textColor: '#B0984A',
    backgroundColor: '#FFEBB2',
    nightIcon: 'fas fa-moon',
    nightIconColor: '#D6DDF8',
    nightTextColor: '#D6DDF8',
    nightBackgroundColor: '#222335',
  },
  {
    themeName: 'Clouds',
    icon: 'fas fa-cloud-sun',
    iconColor:
      'linear-gradient(to top left, #646d73 0%, #646d73 40%, #A4B2B9 49%, #FFC102 56%, #FFC102 100%)',
    textColor: '#646d73',
    backgroundColor: '#DCE7EC',
    nightIcon: 'fas fa-cloud-moon',
    nightIconColor:
      'linear-gradient(to top right, #3A444D 0%, #3A444D 40%, #A4B2B9 49%, #D5DCF7 50%, #D5DCF7 100%)',
    nightTextColor: '#D6DDF8',
    nightBackgroundColor: '#222335',
  },
  {
    themeName: 'Fog',
    icon: 'fas fa-smog',
    iconColor:
      'linear-gradient(to top, #A4B2B9 0%, #A4B2B9 34%, #646d73 35%, #646d73 100%)',
    textColor: '#646d73',
    backgroundColor: '#DCE7EC',
    nightIcon: 'fas fa-cloud-moon',
    nightIconColor:
      'linear-gradient(to top right, #3A444D 0%, #3A444D 40%, #A4B2B9 49%, #D5DCF7 50%, #D5DCF7 100%)',
    nightTextColor: '#D6DDF8',
    nightBackgroundColor: '#222335',
  },
]
